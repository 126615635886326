import React, { ReactElement } from "react";
import MainBanner from "@components/debtreliefanalysis/mainBanner/mainBanner";

import { GetServerSideProps } from "next";

import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import DebtReliefAnalysisWrapper from "@components/layouts/debtReliefAnalysisWrapper";
import OffersList from "@components/shared/offersList";

import FAQ from "@components/debtreliefanalysis/faq";
import HowThisWork from "@components/debtreliefanalysis/howThisWork";
export default function DebtReliefAnalysisHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <DebtReliefAnalysisWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <HowThisWork />
            <FAQ />
        </DebtReliefAnalysisWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
